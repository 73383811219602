// Default sans serif
$font-family-system: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
  "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

// Merge app fonts
$font-family-sans-serif: "Inter", $font-family-system;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New",
  monospace;

// theme.min.css override
:root {
  --font-family-sans-serif: #{$font-family-sans-serif};
  --font-family-monospace: #{$font-family-monospace};
}

// theme.min.css override
body,
.popover,
.ql-container {
  font-family: var(--font-family-sans-serif);
  font-size: 14px;
  line-height: 24px;
}

// theme.min.css override
code,
kbd,
pre,
samp {
  font-family: var(--font-family-monospace);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 0;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
}

.h1,
h1 {
  font-size: 40px;
  line-height: 56px;
}

.h2,
h2 {
  font-size: 32px;
  line-height: 44px;
}
.h3,
h3 {
  font-size: 24px;
  line-height: 36px;
}
.h4,
h4 {
  font-size: 18px;
  line-height: 28px;
}
.h5,
h5 {
  font-size: 16px;
  line-height: 26px;
}
.h6,
h6 {
  font-size: 14px;
  line-height: 24px;
}

// theme.min.css override
.form-control.h1,
.form-control.h2,
.form-control.h3,
.form-control.h4,
.form-control.h5 {
  letter-spacing: 0;
}

// theme.min.css override
.display-1,
.display-2,
.display-3,
.display-4 {
  letter-spacing: 0;
}

// theme.min.css override
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 0;
}

b,
strong {
  font-weight: 600;
}

a {
  color: var(--blue40);

  &:focus,
  &:hover {
    color: var(--blue60);
  }
}

a.black {
  color: _palette(black);

  &:focus,
  &:hover {
    color: _palette(gray-between);
  }
}

.text-primary {
  color: var(--blue40) !important;

  &:focus,
  &:hover {
    color: var(--blue60) !important;
  }
}

.btn-link {
  font-weight: 400;
  color: _palette(blue);
  text-decoration: none;
}

.btn-link:hover {
  color: _palette(blue-dark);
  text-decoration: none;
}

code {
  color: _palette(blue);

  :not(pre) > & {
    padding: 3px 4px;
    border-radius: 5px;
    background: _palette(code-bg);
    border: 1px solid _palette(code-border);
  }
}

.black {
  color: _palette(black) !important;
}

.blue-text {
  color: _palette(blue) !important;
}

.red {
  color: _palette(red) !important;
}

.yellow {
  color: _palette(yellow) !important;
}

.gray {
  color: _palette(gray) !important;
}

.green {
  color: _palette(green) !important;
}

.purple {
  color: _palette(purple) !important;
}

.white {
  color: _palette(white) !important;
}

.paragraph-m-0 p {
  margin: 0px !important;
}

.empty-state-text {
  color: _palette(empty-state);
}
.copy-state-text {
  color: _palette(copy-state);
}

.markdown {
  h1 {
    font-family: inherit;
    font-weight: 600;
    margin-bottom: 1.75rem;
  }
}

.chevron-offset-text-align {
  padding-top: 2px;
  margin-left: 2px;
}

.font-size-50 {
  font-size: 50% !important;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semibold {
  font-weight: 600;
}

.fe {
  line-height: inherit !important;
}

// theme.min.css override
.icon > .fe {
  font-size: 1rem;
}

// theme.min.css override
.navbar-nav .nav-link > .fe {
  font-size: 1rem;
  line-height: 1.4rem;
}
