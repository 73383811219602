.filter-selected-text {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--gray50);
}

@media (max-width: 767.98px) {
  .issue-mute-toggle-small {
    display: contents;
    &-left {
      display: inline;
      float: left;
    }
    &-right {
      display: inline;
      float: right;
    }
  }
}
