hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid var(--gray10);

  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border-top: 0.5px solid var(--gray20);
  }
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #f9fbfd;
  border: 1px solid var(--gray10);
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto;
}
.table td,
.table th {
  padding: 1rem;
  vertical-align: top;
  /* border-top: 1px solid var(--gray10); */
}
.table thead th {
  vertical-align: bottom;
  /* border-bottom: 2px solid var(--gray10); */
  border-bottom: 1px solid var(--gray10);

  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border-bottom: 0.5px solid var(--gray20);
  }
}
.table tbody + tbody {
  border-top: 2px solid var(--gray10);
}
.table-bordered {
  border: 1px solid var(--gray10);
}
.table-bordered td,
.table-bordered th {
  border: 1px solid var(--gray10);

  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border: 0.5px solid var(--gray20);
  }
}
.table .thead-light th {
  color: var(--gray50);
  background-color: #f9fbfd;
  border-color: var(--gray10);
}
.btn-light {
  color: #283e59;
  background-color: var(--gray10);
  border-color: var(--gray10);
}
.btn-light {
  color: #283e59;
  background-color: var(--gray10);
  border-color: var(--gray10);
}
.btn-light.disabled,
.btn-light:disabled {
  color: #283e59;
  background-color: var(--gray10);
  border-color: var(--gray10);
}
.btn-outline-light {
  color: var(--gray10);
  border-color: var(--gray10);
}
.btn-outline-light:hover {
  color: #283e59;
  background-color: var(--gray10);
  border-color: var(--gray10);
}
.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle {
  color: #283e59;
  background-color: var(--gray10);
  border-color: var(--gray10);
}
.dropdown-divider {
  height: 0;
  margin: 0.75rem 0;
  overflow: hidden;
  border-top: 1px solid var(--gray10);

  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border-top: 0.5px solid var(--gray20);
  }
}
.nav-tabs {
  border-bottom: 1px solid var(--gray10);

  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border-bottom: 0.5px solid var(--gray20);
  }
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid var(--gray10);
  border-radius: 0.5rem;
}
.card-header {
  padding: 1rem 1.5rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 1px solid var(--gray10);

  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border-bottom: 0.5px solid var(--gray20);
  }
}
.card-footer {
  padding: 1rem 1.5rem;
  background-color: transparent;
  border-top: 1px solid var(--gray10);

  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border-top: 0.5px solid var(--gray20);
  }
}
.page,
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #12263f;
  background-color: #fff;
  border: 1px solid var(--gray10);
}
.page-link:hover,
.page:hover {
  z-index: 2;
  color: #12263f;
  text-decoration: none;
  background-color: #f9fbfd;
  border-color: var(--gray10);
}
.page-item.disabled .page,
.page-item.disabled .page-link {
  color: var(--gray50);
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: var(--gray10);
}
.alert-light {
  color: #8490a0;
  background-color: var(--gray10);
  border-color: var(--gray10);
}
.list-group-item {
  position: relative;
  display: block;
  padding: 1rem 1.25rem;
  background-color: transparent;
  border: 1px solid var(--gray10);
}
.toast {
  max-width: 300px;
  overflow: hidden;
  font-size: 0.9375rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid var(--gray10);
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.375rem;
}
.toast-header {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  color: #12263f;
  background-color: #fff;
  background-clip: padding-box;
  border-bottom: 1px solid var(--gray10);

  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border-bottom: 0.5px solid var(--gray20);
  }
}
.modal-header {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
  border-bottom: 1px solid var(--gray10);

  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border-bottom: 0.5px solid var(--gray20);
  }
}
.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 1.25rem;
  border-bottom-right-radius: calc(0.5rem - 1px);
  border-bottom-left-radius: calc(0.5rem - 1px);
  border-top: 1px solid var(--gray10);

  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border-top: 0.5px solid var(--gray20);
  }
}
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: var(--gray10);
}
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: var(--gray10);
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: var(--gray10);
}
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: var(--gray10);
}
.deprecated-border {
  border: 1px solid var(--gray10) !important;
}
.border-top {
  border-top: 1px solid var(--gray10) !important;

  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border-top: 0.5px solid var(--gray20);
  }
}
.border-right {
  border-right: 1px solid var(--gray10) !important;

  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border-right: 0.5px solid var(--gray20);
  }
}
.border-bottom {
  border-bottom: 1px solid var(--gray10) !important;

  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border-bottom: 0.5px solid var(--gray20);
  }
}
.border-left {
  border-left: 1px solid var(--gray10) !important;

  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border-left: 0.5px solid var(--gray20);
  }
}
.border-light {
  border-color: var(--gray10) !important;
}
@media print {
  .table-bordered td,
  .table-bordered th {
    border: 1px solid var(--gray10) !important;
  }
  .table-dark tbody + tbody,
  .table-dark td,
  .table-dark th,
  .table-dark thead th {
    border-color: var(--gray10);
  }
  .table .thead-dark th {
    color: inherit;
    border-color: var(--gray10);
  }
}
.alert-light {
  color: #283e59;
  background-color: var(--gray10);
  border-color: var(--gray10);
}
.btn-white {
  border-color: var(--gray10);
}
.card {
  margin-bottom: 1.5rem;
  border-color: var(--gray10);
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}
.card-inactive {
  border-color: var(--gray10);
  border-style: dashed;
  background-color: transparent;
  box-shadow: none;
}
.card-inactive {
  border-color: var(--gray10);
  border-style: dashed;
  background-color: transparent;
  box-shadow: none;
}
.list-group-activity .list-group-item:not(:last-child)::before {
  content: "";
  position: absolute;
  top: 1rem;
  left: 1.25rem;
  height: 100%;
  border-left: 1px solid var(--gray10);
}
.navbar-light {
  background-color: #fff;
  border-color: var(--gray10);
}
.navbar-light .navbar-divider {
  border-color: var(--gray10);
}
.navbar-light .navbar-user {
  border-top-color: var(--gray10);
}
.navbar-light .navbar-collapse::before {
  border-top-color: var(--gray10);
}
.pagination-tabs {
  border-radius: 0;
  border-top: 1px solid var(--gray10);
}
.header-body {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid var(--gray10);
}
.flatpickr-calendar .flatpickr-day.today {
  border-color: var(--gray10);
}
.flatpickr-calendar .flatpickr-day.inRange {
  background-color: var(--gray10);
  border: none;
  border-radius: 0;
  box-shadow:
    -5px 0 0 var(--gray10),
    5px 0 0 var(--gray10);
}
.ql-tooltip .ql-remove::before {
  color: #283e59;
  background-color: #fff;
  border-color: #fff;
  content: "Remove";
  border-color: var(--gray10);
}
