.left-nav-logo {
  padding: 12px 0 12px;
}

@media (max-width: 767.98px) {
  .left-nav-logo svg {
    transform: scale(1.1);
  }
}

.nav-item {
  cursor: pointer;

  .lucide {
    // open-source extension of Feather icons
    margin-right: 12px;
  }
}

.navbar-vertical {
  overflow-y: visible !important;
}

@media (max-width: 767.98px) {
  .logo-divider {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md {
    position: absolute; // override "fixed" position from theme.min.css
  }

  .navbar-vertical .logo-divider {
    margin: 0.75rem -24px 0 !important;
    border-top: 1px solid var(--gray10);

    /* Retina */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      border-top: 0.5px solid var(--gray20);
    }
  }

  .navbar-vertical > [class*="container"] {
    height: 100vh;
    justify-content: flex-start !important;
  }

  .navbar-vertical .navbar-collapse {
    overflow-y: auto;
    max-height: calc(100vh - 46.41px - 12px - 80px);
    padding: 16px 0;

    &.fit-announcement-banner {
      max-height: calc(100vh - 46.41px - 12px - 80px - 48px);
    }
  }

  .navbar-vertical .navbar-user.d-md-flex {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 80px;
    justify-content: start;
  }

  .navbar-vertical.navbar-expand-md .navbar-user {
    border-top-width: 0;
  }
}

.navbar-heading {
  margin: 20px 0 0;
}

.navbar-vertical.navbar-expand-md.fixed-left {
  border-color: var(--gray10);

  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border-color: var(--gray20);
  }
}

.navbar-vertical.navbar-expand-xs.fixed-left {
  border-width: 0 1px 0 0;

  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border-width: 0 0.5px 0 0;
  }
}

@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm.fixed-left {
    border-width: 0 1px 0 0;

    /* Retina */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      border-width: 0 0.5px 0 0;
    }
  }
}

@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md.fixed-left {
    border-width: 0 1px 0 0;

    /* Retina */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      border-width: 0 0.5px 0 0;
    }
  }
}

@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg.fixed-left {
    border-width: 0 1px 0 0;

    /* Retina */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      border-width: 0 0.5px 0 0;
    }
  }
}

@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl.fixed-left {
    border-width: 0 1px 0 0;

    /* Retina */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      border-width: 0 0.5px 0 0;
    }
  }
}

.navbar-light .navbar-nav .nav-link {
  color: _palette(black);
  font-weight: 600;
  margin: 0 12px;
  padding: 8px 12px !important;
  border-radius: 6px;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: _palette(blue);
  background: _palette(bg-readonly);

  .lucide {
    // open-source extension of Feather icons
    stroke: _palette(blue);
  }
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: _palette(blue);
  background: _palette(blue-bg);

  .lucide {
    // open-source extension of Feather icons
    stroke: _palette(blue);
  }
}

// Reset
.navbar-nav .nav-link.active:before {
  border-left: none !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: _palette(blue) !important;
  background-color: _palette(blue-bg) !important;
  border-radius: 6px;
  border-color: transparent;
}

.nav-tabs .nav-link {
  color: _palette(black) !important;
  font-weight: 600;
  padding: 8px 16px;
  display: flex;
  align-items: center;
}

// Used only for IntegrationIssuesScreen card headers
.nav-tabs.card-header-tabs .nav-link {
  padding: 8px 16px !important;
}

.nav-tabs .nav-item {
  padding: 0;
  margin: 0 12px 20px;
}

// Used only for IntegrationIssuesScreen card headers
.nav-tabs.card-header-tabs .nav-item {
  margin: 12px 4px;
}

.dropup {
  flex: 1;
  height: 84%;
  display: flex;
  align-items: center;

  .dropdown-menu {
    width: 104%;
  }
}

.left-nav-dropdown-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.left-nav-dropdown-toggle .user-preview {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.left-nav-dropdown-toggle .avatar {
  margin-right: 12px;
}

.left-nav-dropdown-toggle .user-preview-text .username {
  color: var(--gray90);
  font-weight: 600;
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.left-nav-dropdown-toggle .user-preview-text .header-pretitle {
  color: var(--gray50);
  margin-bottom: 0.3rem;
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.left-nav-dropdown-toggle i {
  color: var(--gray50);
}

.navbar-nav .nav-link > .fe {
  padding-bottom: 0 !important;
}

.sub-navbar-nav {
  display: inherit !important;
  margin-left: 0px;
  margin-right: 0px;
}

.sub-navbar-nav a {
  color: _palette(black);
  border-left: 0px !important;
  font-weight: 600;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.sub-navbar-nav .sub-nav-link.active {
  color: _palette(blue);
  background-color: _palette(blue-bg);
  border-left: 0px !important;
  border-radius: 6px;
}

.sub-navbar-nav .sub-nav-link.active:before {
  border-left: 0px !important;
}

.demo-data-toggle {
  width: 48px;
  transform: scale(calc(2 / 3));

  .custom-switch {
    padding-left: 4rem;
  }
}

.field-mapping-toggle {
  width: 32px;
  transform: scale(calc(2 / 3));
}

.field-mapping-overrides-toggle {
  width: 32px;
  transform: scale(calc(2 / 3));
  margin-left: -8px;
}
