.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;

  box-shadow: 0 0px 5px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 0px 5px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #e63757;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.trial-hero {
  background-image: url("../../../assets/img/mark-quicksilver.png");
  background-position: right;
  background-repeat: no-repeat;
  background-size: 50%;
  margin: -24px;
}
.billing-plan {
  &-type {
    display: flex;
    align-items: center;
  }
  &-name {
    float: left;
    margin: 0px;
    margin-right: 6px;
  }
}
.billing-plan-subheader {
  font-size: 13;
  color: _palette(gray-between);
}

ul.leaders {
  max-width: 400em;
  padding: 0;
  overflow-x: hidden;
  list-style: none;
}
ul.leaders li:before {
  float: left;
  width: 0;
  white-space: nowrap;
  color: #c9d0da;
  content: "...................." "...................." "...................."
    "...................." "...................." "...................." "...................."
    "...................." "....................";
}
ul.leaders span:first-child {
  padding-right: 0.33em;
  background: #f9fafb;
}
ul.leaders span + span {
  float: right;
  padding-left: 0.33em;
  background: #f9fafb;
}

.auto-pay {
  display: flex;
  margin-right: 40px;
  margin-bottom: 0px;

  &-label {
    display: flex;
    align-items: center;
    margin-right: 15px;
    margin-bottom: 0px;
    // Font
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0;
    text-align: right;
    color: #939aa5;

    &-enabled {
      color: #1a9b87;
    }
  }
}
