.log-side-panel {
  background: white;
  position: fixed;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 1050;
  overflow-y: auto;
  box-shadow: -1px 0px 0px var(--gray10);
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.log-entry-side-panel-banner {
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 12px;
  width: 100%;
  position: relative;
  color: #8492a6;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex-wrap: inherit;
}

.log-entry-side-panel-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 12px;
  width: 100%;
  position: relative;
  color: #8492a6;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex-wrap: inherit;
  margin-top: 2px;
  margin-bottom: 2px;
  height: 104px;
  &-row {
    width: 100%;
    position: relative;
  }
  &-title {
    color: _palette(black);
    font-weight: 600;
    font-size: 24px;
  }
  &-badge {
    overflow: hidden;
    font-size: initial;
    font-size: 12px;
    font-weight: 600;
  }
  &-subtitle {
    color: #727581;
    font-size: 12px;
    line-height: 22px;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0;
  }
}
.log-card {
  &-header {
    height: 104px;
    &-image {
      width: 56px;
      height: 56px;
    }
  }
  &-body {
    padding-top: 0;
  }
}
.log-rounded {
  border-radius: 16px;
}
.log-entry-section {
  &-header {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 12px;
  }
  &-key-text {
    font-size: 12px;
    font-weight: 600;
    color: #8492a6;
  }
  &-value-text {
    font-size: 15px;
    font-weight: 400;
  }
}
.log-data-text {
  color: _palette(black);
  font-weight: 400;
  font-size: 15;
}
.log-dot-seperator {
  content: "\00B7";
  font-size: 13px;
  line-height: 13px;
}
.log-header-logo {
  height: 12px;
  padding-bottom: 2px;
}
.log-request-arrow {
  align-items: center;
  position: relative;
}
