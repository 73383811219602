.legalBackdrop {
  background-color: #ffffff !important;
  opacity: 0.92 !important;
}

.legalContent {
  background-color: #ffffff !important;
  border: 1px solid #eaeef3 !important;
  box-shadow: 0px 12px 24px rgba(18, 38, 63, 0.03) !important;
  border-radius: 8px !important;
}
